.TEST {
    position: absolute;
    color: black;
    font-size: 50px;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.Spinner {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.printBtn1 {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 5%;
    right: 5%;
    background-image: url('../pictures/print.png');
    background-size: 34px 34px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50% !important;
    border: grey;
    z-index: 100000;
}

.printLength {
    position: absolute;
    left: 35px;
    color: 	#FF6347;
    font-family: Arial, Helvetica, sans-serif;
}

.exitLoaders {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 2%;
    right: 2%;
    border-radius: 50% !important;
    border: grey;
    z-index: 100000;
}

.progressText {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    top: 5px;
    left: 5px;
}
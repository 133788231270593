@media screen and (min-width: 70em) {
    .rullakkoNimi {
        position: relative;
        vertical-align: middle;
        margin: 0px 0px 5px 0;
        bottom: 17px !important;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    .rullakkoHolder {
        padding: 10px 20px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
        color: white;
    }
    .addRullakko {
        position: relative;
        width: 50px;
        height: 50px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 50% !important;
        font-size: 30px !important;
        text-align: center;
    }
    .bottomRulla {
        padding-bottom: 50px;
    }
    .rullakkoKortti {
        width: auto;
        height: auto;
        padding: 10px;
    }
    .rullakot {
        position: absolute;
        width: 100px;
        right: 10px;
        top: 225px;
    }
    .hyllyt {
        position: absolute;
        width: 100px;
        right: 115px;
        top: 225px;
    }
    .ValmisRow {
        background-color: green;
    }
    .ArkistoRow {
        background-color: #F0AD4E;
    }
    .vieExcel {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .poistaBtn {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .muokkaaBtn {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .valmisBtn {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
        background-color: #238423 !important;
        border: #238423 !important;
    }
    .valmisBtn:hover {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
        background-color: #007000 !important;
        border: #238423 !important;
    }
    .dialogBtn {
        width: 100% !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .label {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .KukkaTable {
        width: auto;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
        max-width: 210px;
        font-size: 20px;
    }
    .lisatietoTable {
        width: auto;
        max-width: 125px;
        font-size: 15px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
    }
    .tableLoaders {
        background-color: gray;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        z-index: 1000000;
    }
    .tableLoaders {
        background-color: gray;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        z-index: 1000000;
    }
    .keraamassaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerayksessaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #d4ca15 !important;
        color: black !important;
    }
    .kerattyBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #1fa30d !important;
        color: black !important;
    }
    .eioleBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #db2121 !important;
        color: yellow !important;
    }
    .keraamassaBtn:hover, .kerayksessaBtn:hover, .kerattyBtn:hover, .eioleBtn:hover {
        border-color: blue;
    }
    .kerayksessaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #d4ca15 !important;
        color: black !important;
    }
    .kerattyBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #1fa30d !important;
        color: black !important;
    }
    .eioleBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #db2121 !important;
        color: yellow !important;
    }
    .keraamassaBtn:hover, .kerayksessaBtn:hover, .kerattyBtn:hover, .eioleBtn:hover {
        border-color: blue;
    }
    .kerattyMaara {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerattyMaara:hover {
        border-color: blue;
    }
    .block {
        display: inline-block;
        width: 100%;
    }
    .myDiv {
        width: 100%;
    }
    .NavBlock {
        width: 100%;
        height: 70px;
    }
    .inputlabel {
        width: 100% !important;
        display: inline-block !important;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
    .inputlabelA {
        width: 85% !important;
        display: inline-block !important;
    }
    .delProduct {
        width: 15% !important;
        display: inline-block !important;
    }
    .btnlabel {
        display: inline-block;
        width: 10px;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .warningBox {
        text-align: center;
    }
    .lisatieto {
        position: absolute;
        max-width: 250px !important;
        height: 200px !important;
        word-break: break-all !important;
        right: 10px;
        display: inline-block;
        text-align: right;
    }
    .orderLisatietoTable {
        position: absolute;
        max-width: 250px !important;
        height: 200px !important;
        word-break: break-all !important;
        top: 90px;
        right: 10px;
        display: inline-block;
        text-align: right;
    }
    .Cards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .UpdateCards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        left: -5px;
        height: 40px;
        width: 190px;
        text-align: center;
        font-size: 20px;
    }
    .DateUpdate {
        position: relative;
        top: -10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerOrderInfo {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM {
        position: relative;
        top: -10px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .taulukkoDivider {
        width: 100%;
        height: 50px;
    }
    .addFlower {
        position: relative;
        top: 10px;
        width: 100% !important;
    }
    .addFlowerInput {
        position: relative;
        top: 10px;
        width: 70px !important;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
    }
    .hidden {
        visibility: hidden;
    }
    .loaders {
        position: relative;
        left: -20px;
    }
}

@media screen and (max-width: 1120px) {
    .rullakkoNimi {
        position: relative;
        vertical-align: middle;
        margin: 0px 0px 5px 0;
        bottom: 17px !important;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    .rullakkoHolder {
        padding: 10px 20px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
        color: white;
    }
    .addRullakko {
        position: relative;
        width: 50px;
        height: 50px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 50% !important;
        font-size: 30px !important;
        text-align: center;
    }
    .bottomRulla {
        padding-bottom: 20px;
    }
    .rullakkoKortti {
        width: auto;
        height: auto;
        padding: 10px;
    }
    .rullakot {
        position: absolute;
        width: 100px;
        right: 10px;
        top: 225px;
    }
    .hyllyt {
        position: absolute;
        width: 100px;
        right: 115px;
        top: 225px;
    }
    .ValmisRow {
        background-color: green;
    }
    .ArkistoRow {
        background-color: #F0AD4E;
    }
    .Tables {
        width: 99%;
        position: relative;
        left: -10px;
    }
    .vieExcel {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .poistaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .muokkaaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .valmisBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
        background-color: #238423 !important;
        border: #238423 !important;
    }
    .valmisBtn:hover {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
        background-color: #007000 !important;
        border: #238423 !important;
    }
    .dialogBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .label {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .tableLoaders {
        background-color: gray;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        z-index: 1000000;
    }
    .keraamassaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerayksessaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #d4ca15 !important;
        color: black !important;
    }
    .kerattyBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #1fa30d !important;
        color: black !important;
    }
    .eioleBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #db2121 !important;
        color: yellow !important;
    }
    .keraamassaBtn:hover, .kerayksessaBtn:hover, .kerattyBtn:hover, .eioleBtn:hover {
        border-color: blue;
    }
    .kerattyMaara {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerattyMaara:hover {
        border-color: blue;
    }
    .block {
        display: inline-block;
        width: 100%;
    }
    .myDiv {
        width: 100%;
    }
    .NavBlock {
        width: 100%;
        height: 70px;
    }
    .inputlabel {
        width: 147px !important;
        display: inline-block !important;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
    .btnlabel {
        display: inline-block;
        width: 10px;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .warningBox {
        text-align: center;
    }
    .lisatieto {
        position: absolute;
        max-width: 250px !important;
        height: 200px !important;
        word-break: break-all !important;
        right: 10px;
        display: inline-block;
        text-align: right;
    }
    .orderLisatietoTable {
        position: absolute;
        max-width: 250px !important;
        height: 200px !important;
        word-break: break-all !important;
        top: 90px;
        right: 10px;
        display: inline-block;
        text-align: right;
    }
    .KukkaTable {
        word-break: normal;
    }
    .lisatietoTable {
        word-break: normal;
    }
    .Cards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .UpdateCards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        left: -5px;
        height: 40px;
        width: 190px;
        text-align: center;
        font-size: 20px;
    }
    .DateUpdate {
        position: relative;
        top: -10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerOrderInfo {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM {
        position: relative;
        top: -10px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .taulukkoDivider {
        width: 100%;
        height: 50px;
    }
    .addFlower {
        position: relative;
        top: 10px;
        width: 100% !important;
    }
    .addFlowerInput {
        position: relative;
        top: 10px;
        width: 70px !important;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
    }
    .hidden {
        visibility: hidden;
    }
    .loaders {
        position: relative;
        left: -20px;
    }
}

@media screen and (max-width: 910px) {
    .rullakkoNimi {
        position: relative;
        vertical-align: middle;
        margin: 0px 0px 5px 0;
        bottom: 17px !important;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    .rullakkoHolder {
        padding: 10px 20px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
        color: white;
    }
    .addRullakko {
        position: relative;
        width: 50px;
        height: 50px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 50% !important;
        font-size: 30px !important;
        text-align: center;
    }
    .bottomRulla {
        padding-bottom: 20px;
    }
    .rullakkoKortti {
        width: auto;
        height: auto;
        padding: 10px;
    }
    .rullakot {
        position: absolute;
        width: 100px;
        right: 10px;
        top: 225px;
    }
    .hyllyt {
        position: absolute;
        width: 100px;
        right: 115px;
        top: 225px;
    }
    .ValmisRow {
        background-color: green;
    }
    .ArkistoRow {
        background-color: #F0AD4E;
    }
    .vieExcel {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .poistaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .muokkaaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .valmisBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .dialogBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .label {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .tableLoaders {
        background-color: gray;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        z-index: 1000000;
    }
    .keraamassaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerayksessaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #d4ca15 !important;
        color: black !important;
    }
    .kerattyBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #1fa30d !important;
        color: black !important;
    }
    .eioleBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #db2121 !important;
        color: yellow !important;
    }
    .keraamassaBtn:hover, .kerayksessaBtn:hover, .kerattyBtn:hover, .eioleBtn:hover {
        border-color: blue;
    }
    .kerattyMaara {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerattyMaara:hover {
        border-color: blue;
    }
    .block {
        display: inline-block;
        width: 100%;
    }
    .myDiv {
        width: 100%;
    }
    .NavBlock {
        width: 100%;
        height: 70px;
    }
    .inputlabel {
        width: 500px !important;
        display: inline-block !important;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
    .btnlabel {
        display: inline-block;
        width: 10px;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .warningBox {
        text-align: center;
    }
    .lisatieto {
        position: absolute;
        max-width: 120px !important;
        height: 100px !important;
        word-break: break-all !important;
        right: 40px;
        display: inline-block;
        text-align: right;
    }
    .orderLisatietoTable {
        position: absolute;
        max-width: 120px !important;
        height: 100px !important;
        word-break: break-all !important;
        top: 90px;
        right: 40px;
        display: inline-block;
        text-align: right;
    }
    .KukkaTable {
        word-break: normal;
    }
    .lisatietoTable {
        word-break: normal;
    }
    .Cards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .UpdateCards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        left: -5px;
        height: 40px;
        width: 190px;
        text-align: center;
        font-size: 20px;
    }
    .DateUpdate {
        position: relative;
        top: -10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 175px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerOrderInfo {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 175px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM {
        position: relative;
        top: -10px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .taulukkoDivider {
        width: 100%;
        height: 50px;
    }
    .addFlower {
        position: relative;
        top: 10px;
        width: 100% !important;
    }
    .addFlowerInput {
        position: relative;
        top: 10px;
        width: 70px !important;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
    }
    .hidden {
        visibility: hidden;
    }
    .loaders {
        position: relative;
        left: -20px;
    }
}

@media screen and (max-width: 375px) {
    .rullakkoNimi {
        position: relative;
        vertical-align: middle;
        margin: 0px 0px 5px 0;
        bottom: 17px !important;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    .rullakkoHolder {
        padding: 10px 20px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
        color: white;
    }
    .addRullakko {
        position: relative;
        width: 50px;
        height: 50px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 50% !important;
        font-size: 30px !important;
        text-align: center;
    }
    .bottomRulla {
        padding-bottom: 20px;
    }
    .rullakkoKortti {
        width: auto;
        height: auto;
        padding: 10px;
    }
    .rullakot {
        position: absolute;
        width: 100px;
        right: 10px;
        top: 225px;
    }
    .hyllyt {
        position: absolute;
        width: 100px;
        right: 115px;
        top: 225px;
    }
    .ValmisRow {
        background-color: green;
    }
    .ArkistoRow {
        background-color: #F0AD4E;
    }
    .vieExcel {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .poistaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .muokkaaBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .valmisBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .dialogBtn {
        width: 99% !important;
        position: relative !important;
        left: -10px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: white !important;
    }
    .label {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .tableLoaders {
        background-color: gray;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 50px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        z-index: 1000000;
    }
    .keraamassaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerayksessaBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #d4ca15 !important;
        color: black !important;
    }
    .kerattyBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #1fa30d !important;
        color: black !important;
    }
    .eioleBtn {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
        background-color: #db2121 !important;
        color: yellow !important;
    }
    .keraamassaBtn:hover, .kerayksessaBtn:hover, .kerattyBtn:hover, .eioleBtn:hover {
        border-color: blue;
    }
    .kerattyMaara {
        display: inline-block !important;
        width: 147px !important;
        text-align: center;
    }
    .kerattyMaara:hover {
        border-color: blue;
    }
    .block {
        display: inline-block;
        width: 100%;
    }
    .myDiv {
        width: 100%;
    }
    .NavBlock {
        width: 100%;
        height: 70px;
    }
    .inputlabel {
        width: 250px !important;
        display: inline-block !important;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
    .btnlabel {
        display: inline-block;
        width: 10px;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px !important;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .warningBox {
        text-align: center;
    }
    .lisatieto {
        position: absolute;
        max-width: 100px !important;
        height: 100px !important;
        word-break: break-all !important;
        right: 40px;
        display: inline-block;
        text-align: right;
    }
    .orderLisatietoTable {
        position: absolute;
        max-width: 100px !important;
        height: 100px !important;
        word-break: break-all !important;
        top: 90px;
        right: 40px;
        display: inline-block;
        text-align: right;
    }
    .KukkaTable {
        word-break: normal;
    }
    .lisatietoTable {
        word-break: normal;
    }
    .Cards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .lisatietoMain {
        font-size: 20px !important;
    }
    .UpdateCards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        left: -5px;
        height: 40px;
        width: 190px;
        text-align: center;
        font-size: 20px;
    }
    .DateUpdate {
        position: relative;
        top: -10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 125px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerOrderInfo {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 125px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .taulukkoDivider {
        width: 100%;
        height: 50px;
    }
    .addFlower {
        position: relative;
        top: 10px;
        width: 100% !important;
    }
    .addFlowerInput {
        position: relative;
        top: 10px;
        width: 70px !important;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
    }
    .hidden {
        visibility: hidden;
    }
    .loaders {
        position: relative;
        left: -20px;
    }
}
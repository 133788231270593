@media screen and (min-width: 650px) {
    .palautettu {
        flex-grow: 1;
    }
    .flexerBox {
        display: flex;
    }
    .YEARcount {
        position: relative;
        display: flex;
        top: 70px;
        width: 200px;
        left: 50%;
        transform: translate(-50%);
    }
    .YearInput {
        text-align: center;
        font-size: 20px !important;
    }
    .buttonArrow {
        font-weight: bold !important;
        font-size: 20px !important;
    }
    .lainassa {
        flex-grow: 1;
    }
    .flexR {
        width: 100%;
        display: flex;
    }
    .rullakkoTop {
        position: relative;
        top: 60px;
        margin-top: 20px !important;
    }
    .rullakkoCard {
        width: 95%;
        height: auto;
        padding: 10px 10px 10px 10px;
        left: 50%;
        transform: translate(-50%);
    }
    .rullaList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .hyllyList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: white;
        border: 1px solid dodgerblue;
    }
    .hyllyRullaList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .hyllyRullaList2 {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .kauppaRulla {
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .hyllytRulla {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .hyllytRulla2 {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .rullakotRulla {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .tallennaPalautetut {
        flex-grow: 1;
    }
    .inputPalautetut {
        flex-grow: 1;
    }
}

@media screen and (max-width: 650px) {
    .palautettu {
        flex-grow: 1;
    }
    .palautettu {
        flex-grow: 1;
    }
    .YEARcount {
        position: relative;
        display: flex;
        top: 70px;
        width: 200px;
        left: 50%;
        transform: translate(-50%);
    }
    .YearInput {
        text-align: center;
        font-size: 20px !important;
    }
    .buttonArrow {
        font-weight: bold !important;
        font-size: 20px !important;
    }

    .rullakkoTop {
        position: relative;
        top: 60px;
        margin-top: 20px !important;
    }
    .rullakkoCard {
        width: 95% !important; 
        height: auto;
        padding: 10px 10px 10px 10px;
        left: 50%;
        transform: translate(-50%);
    }
    .rullaList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .hyllyList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: white;
        border: 1px solid dodgerblue;
    }
    .hyllyRullaList {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .hyllyRullaList2 {
        position: relative;
        list-style-type: none;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        border: solid 1px black;
        padding: 5px 5px 5px 5px;
        background-color: dodgerblue;
        border: 1px solid #ddd;
    }
    .kauppaRulla {
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .hyllytRulla {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .hyllytRulla2 {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .rullakotRulla {
        position: relative;
        top: 15px;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    .tallennaPalautetut {
        flex-grow: 1;
    }
    .inputPalautetut {
        flex-grow: 1;
    }
}
.calendarDiv {
    position: relative;
    margin-top: 90px;
    width: 100%;
    height: 100%;
}

.btnListCalendar {
    list-style-type: none;
}

.btnDownFall {
    display: flex;
    justify-content: flex-end;
}

.qaInfoBtn {
    width: 38px !important;
    height: 38px !important;
    background-color: Transparent !important;
    border: none !important;
    cursor: pointer !important;
    overflow: hidden !important;
    outline: none !important;
    background-image: url(../pictures/qa.png);
    background-position: center;
    background-size: 35px 35px;
    background-repeat: no-repeat;
}

.qaInfoBtn:hover {
    background-size: 37px 37px;
}

.orderBtnOrders {
    width: 38px !important;
    height: 38px !important;
    background-color: Transparent !important;
    border: none !important;
    cursor: pointer !important;
    overflow: hidden !important;
    outline: none !important;
    background-image: url(../pictures/dropdown.png);
    background-position: center;
    background-size: 37px 37px;
    background-repeat: no-repeat;
}

.pdfBtnDoc {
    width: 38px !important;
    height: 38px !important;
    background-color: Transparent !important;
    border: none !important;
    cursor: pointer !important;
    overflow: hidden !important;
    outline: none !important;
    background-image: url(../pictures/plusCalendar.png);
    background-position: center;
    background-size: 37px 37px;
    background-repeat: no-repeat;
}

.kauppaLI, .positionLI {
    flex: 1;
}

.pdfBtnDoc:hover, .orderBtnOrders:hover {
    background-size: 39px 39px;
}

.btnListCalendarButton {
    width: 250px;
    font-size: 18px !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.headerOrderRekka, .headerOrder {
    flex: 1;
    text-align: left;
}

.headerRekat2 {
    display: flex;
    background-color: black;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    width: 100%;
    text-align: center;
    padding: 5px 5px 5px 5px;
    height: auto;
    margin-top: 10px;
}

.headerRekat {
    background-color: black;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    width: 100%;
    text-align: left;
    padding: 5px 5px 5px 5px;
    height: auto;
    margin-top: 10px;
}



.rullakotCalendar {
    position: relative;
    background-color: dodgerblue;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    top: 0px;
    text-align: left;
    height: auto;
    margin-top: 10px;
}

.flexWidth {
    display: flex;
    width: 100%;
}

.kauppaLists {
    display: flex !important;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    text-align: center;
    padding: 5px 5px 5px 5px;
    height: auto;
}

.kauppaArkistoRow {
    display: flex !important;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    text-align: center;
    padding: 5px 5px 5px 5px;
    height: auto;
    background-color: #F0AD4E;
}

.kauppaValmisRow {
    display: flex !important;
    list-style-type: none;
    border: solid 1px black;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    text-align: center;
    padding: 5px 5px 5px 5px;
    height: auto;
    background-color: green;
}

.kerattavatKaupat {
    position: relative;
    top: auto;
    left: 50%;
    text-align: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    transform: translate(-50%);
}

.middleLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.lastWeek {
    position: relative !important;
    font-size: 20px !important;
    font-weight: bold !important;
}

.nextWeek {
    position: relative !important;
    font-size: 20px !important;
    font-weight: bold !important;
}

.tableBtnContainer {
    display: flex !important;
    position: relative;
    width: 90%;
    top: auto;
    left: 50%;
    transform: translate(-50%);
}

.alignBoxes {
    padding: 10px 10px 10px 10px;
}

.textSizeKalenteriTitle {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.textSizeKalenteri {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
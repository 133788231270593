.progress {
    background-color: #d8d8d8 !important;
    border-radius: 20px !important;
    position: relative !important;
    margin: 15px 0 !important;
    height: 25px !important;
    width: 150px !important;
}

.progress-done {
    background: linear-gradient(to right, #007991, #78ffd6);
    box-shadow: 0 3px 3px -5px #78ffd6, 0 2px 5px #78ffd6;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
    text-overflow: clip;
}

.loaders {
    margin-top: 10px;
    margin-left: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    padding: 5px;
}

.loaders2 {
    position: fixed;
    width: 300px;
    height: 30%;
    transform: translate(-5%, -20%);
    align-items: center;
    bottom: 5%;
    left: 5%;
    border: grey;
    z-index: 100000;
}

.dataFont {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.loaderMargins {
    flex: 1 1 auto;
}

.loaderMargins2 {
    position: relative;
    align-items: center;
    left: 75%;
    transform: translate(-50%);
}

.progress-ready {
    background: linear-gradient(to right, #56ab2f, #a8e063);
    box-shadow: 0 3px 3px -5px #a8e063, 0 2px 5px #a8e063;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
    text-overflow: clip;
}

.progress-needed {
    background: linear-gradient(to right, #f7971e, #ffd200);
    box-shadow: 0 3px 3px -5px #ffd200, 0 2px 5px #ffd200;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
    text-overflow: clip;
}

.progress-cantbedone {
    background: linear-gradient(to right, #e52d27, #b31217);
    box-shadow: 0 3px 3px -5px #78ffd6, 0 2px 5px #78ffd6;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
    text-overflow: clip;
}
@media screen and (min-width: 1130px) {
    .noList {
        list-style-type: none;
    }
    .frontPage {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        min-height: 900px;
        background-color: gainsboro;
    }
    .frontPageMenu {
        position: absolute;
        top: 0;
        left: 0;
        width: 500px;
        height: 100%;
        background: #D3D3D3;
    }
    .frontPagePictureDiv {
        position: absolute;
        top: 0;
        left: 490px;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
    }
    .frontPageSettings {
        position: absolute;
        border: none !important;
        background-color: transparent !important;
        background-image: url("../pictures/settings.png") !important;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 0.5%;
        left: 89%;
        height: 50px;
        width: 50px;
    }

    .frontPageSettings:hover {
        position: absolute;
        top: 0.5%;
        left: 88.5%;
        height: 53px;
        width: 53px;
    }

    .frontText {
        position: absolute;
        left: 550px;
        top: 700px;
        color: #333;
        font-family: 'Exo', sans-serif;
        font-weight: bold;
        font-size: 100px;
        z-index: 100;
    }
    .redirect {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect:hover, .redirect2:hover, .redirect3:hover, .redirect4:hover {
        font-size: 47px !important;
        text-shadow: 4px 4px 4px #333;
    }
    .redirect2 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect3 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect4 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .frontMainBtn {
        position: absolute;
        display: inline-block;
        transform: translate(-50%);
        left: 47.5%;
        top: 400px;
        text-align: center;
    }
    .pictureDot {
        position: absolute;
        background-image: url("../pictures/mannisto-logo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 150px;
        left: 50%;
        transform: translate(-50%);
        height: 125px;
        width: 350px;
        background-color: white;
        border-radius: 20%;
        display: inline-block;
    }
    .Cards {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
    }
    .containDiv {
        display: flex;
        height: auto;
    }
    .adminSettings {
        width: 50%;
        height: auto;
        border-right: solid black 1px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .accountCreate {
        flex-grow: 1;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .userID {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .userEmail {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .userRole {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .deleteUser {
        font-size: 25px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }
}

@media screen and (max-width: 1120px) {
    .noList {
        list-style-type: none;
    }
    .frontPage {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        min-height: 100%;
        background-color: gainsboro;
        overflow: hidden;
    }
    .frontPageMenu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #D3D3D3;
    }
    .frontPagePictureDiv {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }
    .frontPageSettings {
        position: absolute;
        border: none !important;
        background-color: transparent !important;
        background-image: url("../pictures/settings.png") !important;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 0.5%;
        left: 80%;
        height: 50px;
        width: 50px;
    }

    .frontPageSettings:hover {
        position: absolute;
        border: none !important;
        background-color: transparent !important;
        background-image: url("../pictures/settings.png") !important;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 0.5%;
        left: 80%;
        height: 60px;
        width: 60px;
    }

    .frontText {
        visibility: hidden;
    }
    .redirect {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect:hover, .redirect2:hover, .redirect3:hover, .redirect4:hover {
        font-size: 47px !important;
        text-shadow: 4px 4px 4px #333;
    }
    .redirect2 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect3 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }
    .redirect4 {
        font-size: 45px !important;
        font-family: 'Exo', sans-serif;
        font-weight: bold !important;
        color: #333 !important;
        border: none !important;
        background-color: transparent !important;
    }

    .redirect3:hover {
        font-size: 55px !important;
    }
    .frontMainBtn {
        position: absolute;
        display: inline-block;
        transform: translate(-50%);
        left: 47.5%;
        top: 400px;
        text-align: center;
    }
    .pictureDot {
        position: absolute;
        background-image: url("../pictures/iconH.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 50px;
        left: 50%;
        transform: translate(-50%);
        height: 300px;
        width: 300px;
        background-color: white;
        border-radius: 50%;
        display: inline-block;
    }
    .Cards {
        display: inline-block;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .accountCreate {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
    }
    .adminSettings {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
    }
    th, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .userID {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .userEmail {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .userRole {
        font-size: 20px;
        width: auto;
        max-width: 300px;
    }
    .deleteUser {
        font-size: 25px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }
}
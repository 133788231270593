@media screen and (min-width: 1130px) {
    .progressBarsButton{
        position: absolute;
        right: 165px;
        bottom: 10px;
        width: 175px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .ml-auto {
        height: 140px !important;
    }
    .navName {
        font-size: 25px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        width: 90px;
    }
    .addBtn {
        position: absolute;
        right: 10px;
        bottom: 55px;
        width: 150px;
        height: 40px;
        color: indigo;
        background-image: url(../pictures/plus.png);
        background-size: 45px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .logoutBtn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        text-align: center !important;
    }
    tr, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .Datepick {
        position: absolute;
        right: 150px;
        bottom: -75px;
        height: 40px;
        width: 175px;
        text-align: center;
        font-size: 20px;
    }
    .TarkastusBTN {
        position: absolute;
        right: 165px;
        bottom: 100px;
        width: 175px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .SearchBTN {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
    }
    .ReSearchData {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
        background-image: url(../pictures/icon.png);
        background-position: center;
        background-size: 30px 30px;
        background-repeat: no-repeat;
    }
    .searchDiv {
        position: absolute;
        font-size: 20px;
        width: 285px;
        height: 35px;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-self: center;
    }
    .SearchInput {
        font-size: 20px !important;
        width: 250px !important;
        height: 35px !important;
    }
    .locationBtn {
        position: absolute;
        right: 10px;
        bottom: 100px;
        width: 150px !important;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .AddCard {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 70px;
        padding-bottom: 10px;
    }
    .AddDate {
        position: relative;
        top: -30px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerInfo2 {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .Customer {
        position: relative;
        left: 0px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM2 {
        position: relative;
        top: -30px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
}

@media screen and (max-width: 1120px) {
    .progressBarsButton{
        position: absolute;
        right: 165px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .ml-auto {
        height: 140px !important;
    }
    .navName {
        font-size: 25px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        width: 90px;
    }
    .addBtn {
        position: absolute;
        right: 10px;
        bottom: 55px;
        width: 150px;
        height: 40px;
        color: indigo;
        background-image: url(../pictures/plus.png);
        background-size: 45px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .logoutBtn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        text-align: center !important;
    }
    tr, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .Datepick {
        position: absolute;
        right: 150px;
        bottom: -75px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .TarkastusBTN {
        position: absolute;
        right: 165px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        z-index: 1000;
    }
    .SearchBTN {
        font-size: 20px !important;
        width: 25px !important;
        height: 25px !important;
        border: white 1px solid !important;
    }
    .ReSearchData {
        font-size: 20px !important;
        width: 25 !important;
        height: 25px !important;
        border: white 1px solid !important;
        background-image: url(../pictures/icon.png);
        background-position: center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
    }
    .DataCard {
        width: 100%;
    }
    .inputlabel {
        width: 147px !important;
        display: inline-block;
    }
    .searchDiv {
        position: absolute;
        font-size: 20px;
        width: 275px;
        height: 25px;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-self: center;
    }
    .SearchInput {
        font-size: 20px !important;
        width: 250px !important;
        height: 25px !important;
    }
    .locationBtn {
        position: absolute;
        right: 10px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .AddCard {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 70px;
        padding-bottom: 10px;
    }
    .AddDate {
        position: relative;
        top: -30px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerInfo2 {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .Customer {
        position: relative;
        left: 0px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM2 {
        position: relative;
        top: -30px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
}

@media screen and (max-width: 910px) {
    .progressBarsButton{
        position: absolute;
        right: 165px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .ml-auto {
        height: 140px !important;
    }
    .navName {
        font-size: 25px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        width: 90px;
    }
    .addBtn {
        position: absolute;
        right: 10px;
        bottom: 55px;
        width: 150px;
        height: 40px;
        color: indigo;
        background-image: url(../pictures/plus.png);
        background-size: 45px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .logoutBtn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        text-align: center !important;
    }
    tr, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .Datepick {
        position: absolute;
        right: 150px;
        bottom: -75px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .TarkastusBTN {
        position: absolute;
        right: 165px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        z-index: 1000;
    }
    .SearchBTN {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
    }
    .ReSearchData {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
        background-image: url(../pictures/icon.png);
        background-position: center;
        background-size: 30px 30px;
        background-repeat: no-repeat;
    }
    .searchDiv {
        position: absolute;
        font-size: 20px;
        width: 285px;
        height: 35px;
        display: flex;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        align-self: center;
    }
    .SearchInput {
        font-size: 20px !important;
        width: 250px !important;
        height: 35px !important;
    }
    .locationBtn {
        position: absolute;
        right: 10px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .AddCard {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 70px;
        padding-bottom: 10px;
    }
    .AddDate {
        position: relative;
        top: -30px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerInfo2 {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .Customer {
        position: relative;
        left: 0px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM2 {
        position: relative;
        top: -30px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
}

@media screen and (max-width: 375px) {
    .progressBarsButton{
        position: absolute;
        right: 165px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .ml-auto {
        height: 140px !important;
    }

    .navName {
        font-size: 25px !important;
        font-weight: bold !important;
        font-family: Arial, Helvetica, sans-serif !important;
        width: 90px;
    }
    .addBtn {
        position: absolute;
        right: 10px;
        bottom: 55px;
        width: 150px;
        height: 40px;
        color: indigo;
        background-image: url(../pictures/plus.png);
        background-size: 45px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .logoutBtn {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        text-align: center !important;
    }
    tr, td {
        border: 1px solid #000;
        padding: 0.75rem;
        text-align: left;
        font-size: 20px;
    }
    th {
        font-weight: bold;
        white-space: nowrap;
        background: #000;
        color: #fff;
    }
    .Datepick {
        position: absolute;
        right: 150px;
        bottom: -75px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .TarkastusBTN {
        position: absolute;
        right: 165px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
        z-index: 1000;
    }
    .SearchBTN {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
    }
    .ReSearchData {
        font-size: 20px !important;
        width: 35 !important;
        height: 35px !important;
        border: white 1px solid !important;
        background-image: url(../pictures/icon.png);
        background-position: center;
        background-size: 30px 30px;
        background-repeat: no-repeat;
    }
    .searchDiv {
        position: absolute;
        font-size: 20px;
        width: 175px;
        height: 35px;
        display: flex;
        top: 40%;
        left: 32.5%;
        transform: translate(-27%, -55%);
        align-self: center;
    }
    .SearchInput {
        font-size: 15px !important;
        width: 140px !important;
        height: 40px !important;
    }
    .locationBtn {
        position: absolute;
        right: 10px;
        bottom: 100px;
        width: 150px;
        height: 40px;
        font-size: 20px !important;
        font-weight: bold !important;
    }
    .AddCard {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 70px;
        padding-bottom: 10px;
    }
    .AddDate {
        position: relative;
        top: -30px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .KeraysPVM {
        position: absolute;
        top: 10px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .CustomerInfo {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .CustomerInfo2 {
        position: absolute;
        top: 95px;
        right: 10px;
        height: 30px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .Customer {
        position: relative;
        left: 0px;
        height: 80px !important;
        width: 250px !important;
        text-align: right;
        font-size: 20px;
    }
    .ToimitusPVM2 {
        position: relative;
        top: -30px;
        left: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .ToimitusPVMText {
        position: absolute;
        top: 10px;
        left: 208px;
        height: 40px;
        width: 150px;
        text-align: center;
        font-size: 20px;
    }
    .inputlabelU {
        width: 100% !important;
        display: inline-block !important;
    }
}
.Finnish {
    position: relative;
    right: 0px;
    top: 30px;
    background-image: url('../pictures/finland.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 60px;
    height: 35px;
}

.English {
    position: relative;
    right: 10px;
    top: 30px;
    background-image: url('../pictures/english.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 60px;
    height: 35px;
}